import React from "react";
import { useSpring, animated } from "react-spring";

const other = () => {
	const props = useSpring({ opacity: 1, from: { opacity: 0 } });
	return (
			<div className="other">
				<div className="project-items">
					<animated.div style={props}>
						<h3 id="other">A few other I've worked on</h3>
						<div className="flex-grid">
							<div className="col">
							</div>
						</div>
					</animated.div>
				</div>
			</div>
	);
};

export default other;
